<template>
    <q-tr
        :props="data"
        class="clickable"
        @dblclick="onRowDblClick(data.row)"
    >
        <q-td
            key="image"
            :props="data"
            auto-width
        >
            <div class="text-center">
              <img
                  :src="getRowImage(data.row)"
                  style="width: 80px; height: 70px; object-fit: contain;"
                  @error="onImageLoadFailure"
              >
            </div>

            <div class="text-center">
              <q-btn
                  :label="$t(data.row._embedded?.productOffer?.type || '')"
                  style="min-width: 120px;"
                  color="light-blue-9"
                  icon-right="visibility"
                  size="sm"
                  class="q-mt-sm block full-width text-capitalize"
                  no-caps
                  v-if="data.row._embedded?.productOffer?.id"
                  @click="open"
              />

              <q-btn
                  :label="$t(`Change`)"
                  style="min-width: 120px;"
                  color="warning"
                  icon-right="warning"
                  size="sm"
                  class="q-mt-sm block full-width text-capitalize"
                  no-caps
                  v-if="false"
                  @click="searchOffer"
              />
            </div>
        </q-td>

        <q-td
            key="state"
            :props="data"
            auto-width
        >
            <super-dropdown :model="dropdownModel" />
        </q-td>

        <q-td
            key="order"
            :props="data"
            auto-width
        >
            <div v-if="data.row?._embedded?.order">
                <strong>
                    {{data.row?._embedded?.order.id}}
                </strong>
            </div>
        </q-td>

        <q-td
            key="shop"
            :props="data"
            auto-width
        >
            <div v-if="shop">
                <strong>
                    {{ shop }}
                </strong>
            </div>

            <div v-else>
                {{'--'}}
            </div>
        </q-td>

        <q-td
            key="offer"
            :props="data"
            style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
        >
          <div class="text-subtitle1" v-if="data.row._embedded.productOffer">
              {{ `${data.row._embedded.productOffer.name} (${data.row._embedded.productOffer.id})` }}
          </div>

          <div class="text-caption" v-if="data.row._embedded.productOffer">{{ data.row._embedded.productOffer.sku }}</div>

          <div v-if="data.row._embedded.productOffer">{{ data.row._embedded.productOffer.type }}</div>
        </q-td>

        <q-td
            key="count"
            :props="data"
            style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
        >
          <div class="text-sutitle1">
              {{ `${data.row.count}` }}
          </div>
        </q-td>


        <q-td
                key="dimensions"
                :props="data"
                auto-width
        >
            {{'Weight: ' + (data.row.weight?data.row.weight: '0') + appOptions.defaultDimensions.weightUnit}}
            <br>
            {{'Width: ' + (data.row.dimensions ?data.row.dimensions.x : '0') + appOptions.defaultDimensions.dimensionUnit}}
            <br>
            {{'Height: ' + (data.row.dimensions ?data.row.dimensions.y: '0') + appOptions.defaultDimensions.dimensionUnit}}
            <br>
            {{'Length: ' + (data.row.dimensions ?data.row.dimensions.z: '0') + appOptions.defaultDimensions.dimensionUnit}}
        </q-td>

        <q-td
            key="discount"
            :props="data"
            auto-width
        >
            {{'Tax: ' + (data.row.tax?data.row.tax: '0')}}
            <br>
            {{'Discount: ' + (data.row.discountPrice ?data.row.discountPrice : '0')}}
            <br>
            {{'Price: ' + (data.row.price ?data.row.price: '0')}}
        </q-td>

        <q-td
            key="total"
            :props="data"
            auto-width
        >
            <strong>
                {{data.row.total || 0}}
            </strong>

        </q-td>
    </q-tr>
</template>

<script>
// Vuex
import {mapGetters, mapMutations} from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'

export default {
  name: 'OrderProductsTable',
  emits: ['check', 'dblclick', 'open', 'search-offer', 'acceptance-change'],
  components: {
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      required: true,
      default() {
        return null
      }
    }
  },
  data () {
    return {
      shop: null,
      fallbackImage: 'assets/img/fallback-image/package.png',
      total: 0,
      states: [
        {
          buttons: [
            { id: 'active', title: 'Active', transitions: [], color: 'positive' },
            { id: 'inactive', title: 'Inactive', transitions: [], color: 'grey' },
            { id: 'error', title: 'Error', transitions: [], color: 'red-4' },
            { id: 'return', title: 'Return', transitions: [], color: 'danger' },
            { id: 'return_allocated', title: 'Return Allocated', transitions: [], color: 'danger' },
          ]
        }
      ],
    }
  },
  mounted() {
    if(this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.virtualShop) {
      this.shop = this.data.row._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
    } else if (this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.shop && !shop){
      this.shop = this.data.row._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema () {
      const value = this.offerStates[0].buttons.find(({ id }) => id === this.data.row.state) || { id: 'normal', title: 'Active' }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          const query = {
            filter: [
              { type: 'eq', field: 'shop', value: this.data.row._embedded.shop.id }
            ]
          }

          this.$service.offer.save({ state }, this.data.row.id, this.data.row._embedded.shop.id, {}, query)
            .then(item => {
              this.upsertOffer(item)
            })
        },
        value,
        options: this.offerStates
      }

      return option
    },
    dropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: this.states[0].buttons[1].color,
        editableFields: false,
        disabledLabels: true,
        onClick: (state) => {
          this.data.row.state = state

        },
        value: this.states[0].buttons[0],
        options: this.states
      }

      this.states.find(group => {
        let status = group.buttons.find(btn => {
          return btn.id === this.data.row.state
        })

        if (status) {
          option.color = status.color
          option.value = status
        }

        return status
      })

      return option
    },
  },
  methods: {
    ...mapMutations([
      'upsertOffer'
    ]),
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    getRowImage(row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage;
    },
    open () {
      this.$emit('open', this.data.row)
    },
    isItemOfferChangeAllowed (row) {
      return row._embedded?.productOffer?.id
    },
    searchOffer () {
      this.$emit('search-offer', this.data.row)
      this.$emit('product-change',this.data.row._embedded.order)

    },
  }
}
</script>
